export const Youtube = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      height="44"
      viewBox="0 0 44 44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M43.0817 11.8415C42.5742 9.93226 41.0839 8.43391 39.1908 7.9264C35.7591 7 22 7 22 7C22 7 8.24094 7 4.80923 7.9264C2.91615 8.43391 1.42585 9.93226 0.918345 11.8415C0 15.2973 0 22.4991 0 22.4991C0 22.4991 0 29.7008 0.918345 33.1567C1.42585 35.0659 2.91615 36.4998 4.80923 37.0073C8.24094 37.9337 22 37.9337 22 37.9337C22 37.9337 35.7591 37.9337 39.1908 37.0073C41.0839 36.4998 42.5742 35.0579 43.0817 33.1567C44 29.7008 44 22.4991 44 22.4991C44 22.4991 44 15.2973 43.0817 11.8415ZM17.5049 29.0403V15.9579L29.0004 22.4991L17.5049 29.0403Z" />
    </svg>
  );
};
