export const X = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      height="44"
      viewBox="0 0 50 44"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M39.3742 0H47.0448L30.2912 18.6365L50 44H34.5719L22.4794 28.6212L8.65928 44H0.977836L18.894 24.0625L0 0H15.8192L26.7384 14.0567L39.3742 0ZM36.6797 39.5365H40.9279L13.505 4.23077H8.94176L36.6797 39.5365Z" />
    </svg>
  );
};
