import { SVGProps } from 'react';

export const Facebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    height="44"
    viewBox="0 0 24 44"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M22.468 21.9576H16.4513V44H7.33549V21.9576H3V14.211H7.33549V9.19815C7.33549 5.61337 9.03832 0 16.5324 0L23.2848 0.0282497V7.54761H18.3855C17.5819 7.54761 16.4519 7.94914 16.4519 9.65917V14.2183H23.2644L22.468 21.9576Z" />
  </svg>
);
