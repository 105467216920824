export const Instagram = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      height="44"
      viewBox="0 0 44 44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M22.0049 10.719C15.762 10.719 10.7264 15.7557 10.7264 22C10.7264 28.2443 15.762 33.281 22.0049 33.281C28.2479 33.281 33.2834 28.2443 33.2834 22C33.2834 15.7557 28.2479 10.719 22.0049 10.719ZM22.0049 29.3342C17.9706 29.3342 14.6724 26.0451 14.6724 22C14.6724 17.9549 17.9607 14.6658 22.0049 14.6658C26.0491 14.6658 29.3374 17.9549 29.3374 22C29.3374 26.0451 26.0393 29.3342 22.0049 29.3342ZM36.3755 10.2575C36.3755 11.7204 35.1975 12.8888 33.7448 12.8888C32.2822 12.8888 31.1141 11.7106 31.1141 10.2575C31.1141 8.80442 32.292 7.62624 33.7448 7.62624C35.1975 7.62624 36.3755 8.80442 36.3755 10.2575ZM43.8454 12.928C43.6785 9.40332 42.8736 6.28116 40.292 3.7088C37.7202 1.13645 34.5988 0.331362 31.0748 0.154636C27.4429 -0.0515452 16.5571 -0.0515452 12.9252 0.154636C9.41104 0.321544 6.28957 1.12663 3.70798 3.69898C1.12638 6.27134 0.331288 9.39351 0.154601 12.9182C-0.0515337 16.5509 -0.0515337 27.4392 0.154601 31.072C0.321472 34.5967 1.12638 37.7188 3.70798 40.2912C6.28957 42.8635 9.40123 43.6686 12.9252 43.8454C16.5571 44.0515 27.4429 44.0515 31.0748 43.8454C34.5988 43.6785 37.7202 42.8734 40.292 40.2912C42.8638 37.7188 43.6687 34.5967 43.8454 31.072C44.0515 27.4392 44.0515 16.5607 43.8454 12.928ZM39.1534 34.9698C38.3877 36.8941 36.9055 38.3767 34.9718 39.1523C32.0761 40.301 25.2049 40.0359 22.0049 40.0359C18.8049 40.0359 11.9239 40.2912 9.03804 39.1523C7.11411 38.3865 5.6319 36.9039 4.85644 34.9698C3.70798 32.0734 3.97301 25.2007 3.97301 22C3.97301 18.7993 3.71779 11.9168 4.85644 9.03024C5.62209 7.10588 7.10429 5.62334 9.03804 4.84771C11.9337 3.69898 18.8049 3.96407 22.0049 3.96407C25.2049 3.96407 32.0859 3.7088 34.9718 4.84771C36.8957 5.61352 38.3779 7.09606 39.1534 9.03024C40.3018 11.9266 40.0368 18.7993 40.0368 22C40.0368 25.2007 40.3018 32.0832 39.1534 34.9698Z" />
    </svg>
  );
};
