import { SVGProps } from 'react';

export const Check = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      height="1em"
      viewBox="0 0 448 512"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M447.9 142.5l-23.2 22L181 395.3l-22 20.8-22-20.8L23.2 287.6 0 265.6l44-46.5 23.2 22L159 328 380.7 118l23.2-22 44 46.5z" />
    </svg>
  );
};
