import type { SVGProps } from 'react';

export const On3 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      height="70"
      viewBox="0 0 156 70"
      width="156"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M47.9566 46.4979C47.1637 49.0308 45.9744 51.1638 44.2566 52.8968C42.5388 54.6299 40.4245 56.0964 37.9139 57.0296C35.2711 57.9626 32.2319 58.496 28.6642 58.496H22.4535C18.8858 58.496 15.8465 57.9626 13.2037 57.0296C10.5609 56.0964 8.44669 54.6299 6.861 52.8968C5.14319 51.1638 3.95392 49.0308 3.16109 46.4979C2.36825 43.965 1.97183 41.1654 1.97183 38.0993V30.6338C1.97183 27.4343 2.36825 24.6347 3.16109 22.1018C3.95392 19.5689 5.14319 17.4358 6.861 15.5696C8.57883 13.8365 10.6931 12.3701 13.2037 11.4369C15.8465 10.5037 18.8858 9.97046 22.4535 9.97046H28.6642C32.2319 9.97046 35.2711 10.5037 37.9139 11.4369C40.5566 12.3701 42.6709 13.8365 44.2566 15.5696C45.9744 17.3027 47.1637 19.5689 47.9566 22.1018C48.7494 24.6347 49.1457 27.4343 49.1457 30.6338V38.0993C49.278 41.1654 48.8815 43.965 47.9566 46.4979ZM12.543 49.1642C14.393 51.2971 17.4322 52.3636 21.6607 52.3636H29.7212C33.9497 52.3636 36.9889 51.2971 38.8388 49.1642C40.6889 47.0311 41.4817 43.965 41.4817 39.699V28.7674C41.4817 24.5014 40.5566 21.4353 38.8388 19.3022C36.9889 17.1693 33.9497 16.2361 29.7212 16.2361H21.6607C17.4322 16.2361 14.393 17.3027 12.543 19.3022C10.6931 21.4353 9.7681 24.5014 9.7681 28.7674V39.699C9.7681 43.965 10.6931 47.1645 12.543 49.1642Z" />
      <path d="M90.5052 59.4293C88.5231 59.4293 86.9374 58.8962 85.616 57.8296C84.4267 56.7631 83.3696 55.43 82.3124 53.8302L66.3236 27.8345C66.0592 27.3011 65.795 26.9013 65.5307 26.6346C65.3986 26.5013 65.3986 26.5013 65.2665 26.5013C65.0022 26.5013 64.8701 26.6346 64.8701 26.6346C64.7378 26.768 64.7378 27.1678 64.7378 27.5678V58.7628H57.4702V27.8345C57.4702 25.5681 57.9988 23.5685 59.188 21.9688C60.3773 20.2357 62.3594 19.4357 65.0022 19.4357C66.8521 19.4357 68.4378 19.9691 69.7592 21.1688C70.9485 22.2353 72.1378 23.7017 73.3269 25.7014L88.9195 51.2973C89.1839 51.6973 89.448 52.0971 89.7124 52.2304C89.8445 52.3638 89.9766 52.4971 90.1088 52.4971C90.373 52.4971 90.373 52.3638 90.5052 52.3638C90.6373 52.2304 90.6373 51.9639 90.6373 51.6973V20.1024H97.7729V51.4306C97.7729 53.8302 97.1122 55.6966 95.9229 57.1631C94.7336 58.6295 92.8837 59.4293 90.5052 59.4293Z" />
      <path
        clipRule="evenodd"
        d="M119.708 68.8943H141.115C148.119 68.8943 153.933 63.0286 153.801 55.8297V14.9031C153.801 7.83755 148.119 1.97183 140.983 1.97183H119.708C112.705 1.97183 106.891 7.70423 106.891 14.9031V55.9631C106.891 63.0286 112.573 68.8943 119.708 68.8943ZM131.733 59.4286C131.998 59.4286 132.394 59.4286 132.526 59.2952C138.208 58.8954 142.965 54.7628 144.155 49.2969C144.287 48.4971 144.419 47.5639 144.419 46.764C144.419 42.6314 142.304 38.7653 138.737 36.3657L137.547 35.4325L138.737 34.4993C142.304 32.233 144.419 28.3669 144.419 24.1009C144.419 23.1678 144.287 22.3679 144.155 21.568C143.097 16.1023 138.208 11.9696 132.526 11.5697H116.141V21.3014H132.262C133.715 21.4347 134.905 22.6346 135.169 23.9676C135.301 24.1009 135.301 24.3676 135.301 24.6343C135.301 27.0118 132.13 29.0756 129.243 30.9549C127.921 31.8154 126.659 32.6372 125.787 33.4328V37.6987C126.66 38.4951 127.924 39.2915 129.248 40.1252C132.134 41.9431 135.301 43.9383 135.301 46.4973C135.301 46.5526 135.278 46.6307 135.252 46.7222C135.214 46.8516 135.169 47.0078 135.169 47.1639C134.905 48.4971 133.715 49.5636 132.13 49.8301H116.141V59.4286H131.733Z"
        fillRule="evenodd"
      />
    </svg>
  );
};
