import type { SVGProps } from 'react';

export const Espn = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      height="57"
      viewBox="0 0 195 57"
      width="195"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M59.6517 4.00093C55.9713 4.00093 49.477 8.26371 49.477 17.844H145.12C146.419 3.62739 136.547 4.00093 136.547 4.00093C136.547 4.00093 61.6868 4.00093 59.6517 4.00093ZM48.6543 22.8978C48.6543 22.8978 48.6543 25.8422 48.6543 27.3803C48.6543 30.4126 51.6418 34.126 56.2097 34.126C59.0671 34.126 79.3951 34.148 79.3951 34.148L78.6592 39.9269H47.2688L45.602 53.001C45.602 53.001 79.0488 53.001 82.3826 53.001C85.4783 53.001 92.6656 50.4742 93.8994 43.0032C93.8994 43.0032 95.3283 30.7422 95.3716 30.2808C95.5663 27.9076 94.1159 22.8978 87.7513 22.8978C84.4391 22.8978 48.6543 22.8978 48.6543 22.8978ZM98.6839 22.8978H144.579L143.561 28.1274C143.561 28.1274 142.544 34.1041 135.443 34.1041C128.342 34.1041 112.95 34.1041 112.95 34.1041L110.828 53.001H95.2634L98.6839 22.8978ZM146.981 17.844H194.067C196.188 8.59332 191.772 4.00093 187.161 4.00093C181.705 4.00093 168.413 4.00093 148.735 4.00093L146.981 17.844ZM158.585 53.001H143.02L146.419 22.8978H162.179L158.585 53.001ZM189.91 53.001H174.345L177.765 22.8978H193.526L189.91 53.001ZM4.5133 22.8978H46.6193L45.2338 34.126H19.3425L18.5198 39.9269H44.4979L42.9393 53.023H1.00626L4.5133 22.8978ZM6.63484 4.00093H48.7411L46.944 17.844H5.16276L6.63484 4.00093Z" />
    </svg>
  );
};
