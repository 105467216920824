import type { SVGProps } from 'react';

export const Rivals = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      height="57"
      viewBox="0 0 322 57"
      width="322"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16.1697 51.8179H0.664886V4.17648H59.8181L69.1823 11.9193V25.6194L59.8181 33.3622H48.547L70.97 51.8235H51.589L16.1697 22.6379V51.8179ZM16.1697 16.0854V22.6379H53.6775V16.0854H16.1697ZM89.1535 51.8179H73.6487V4.17648H89.1535V51.8179ZM94.8174 4.17648H110.322V37.585L150.872 4.17648H170.253L112.411 51.8179H104.182L94.8174 44.0751V4.17648ZM147.887 45.9229L140.73 51.8179H121.349L179.191 4.17648H189.208L198.572 11.9193V51.8235H183.068V16.9186L160.883 35.2043H174.538L183.062 45.9229H147.887ZM219.446 39.9089H247.47L261.959 51.8179H213.3L203.936 44.0751V4.17648H219.44V39.9089H219.446ZM269.53 16.0854V22.6379H310.971L320.335 30.3807V44.0808L310.971 51.8235H269.11L254.621 39.9146H305.426V33.3622H263.985L254.621 25.6194V11.9193L263.985 4.17648H305.846L320.335 16.0854H269.53Z" />
    </svg>
  );
};
