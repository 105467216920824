export const Tiktok = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      height="44"
      viewBox="0 0 38 44"
      width="38"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M37.84 18.0401C34.1201 18.0482 30.4918 16.8666 27.4678 14.6624V30.0295C27.4669 32.8756 26.6121 35.6535 25.0176 37.9918C23.4231 40.3302 21.165 42.1174 18.5452 43.1144C15.9255 44.1114 13.069 44.2708 10.3578 43.5712C7.64662 42.8715 5.21001 41.3463 3.37383 39.1994C1.53766 37.0525 0.38946 34.3863 0.0828042 31.5573C-0.223851 28.7284 0.325655 25.8717 1.65783 23.3692C2.99001 20.8666 5.04134 18.8377 7.53748 17.5536C10.0336 16.2695 12.8556 15.7915 15.6259 16.1836V23.9102C14.3592 23.5044 12.9988 23.5163 11.7391 23.9441C10.4794 24.3719 9.38471 25.1937 8.61138 26.2923C7.83806 27.3909 7.42562 28.71 7.43296 30.0613C7.4403 31.4126 7.86705 32.727 8.65226 33.8168C9.43748 34.9066 10.541 35.7161 11.8053 36.1297C13.0696 36.5433 14.43 36.5399 15.6922 36.1199C16.9545 35.6999 18.0541 34.8849 18.834 33.7911C19.6138 32.6974 20.0342 31.3808 20.0349 30.0295V0H27.4678C27.4636 0.639665 27.5173 1.27836 27.6283 1.908C27.8868 3.3115 28.4238 4.64662 29.2066 5.83177C29.9893 7.01692 31.0014 8.0272 32.1809 8.80086C33.8598 9.9292 35.8279 10.53 37.84 10.5284V18.0401Z" />
    </svg>
  );
};
